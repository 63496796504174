<script>
import { write } from 'clipboardy';
import { SimpleBar } from "simplebar-vue3";
import { nextTick } from 'vue'

export default {
  emits: ['profileClick', 'sendMessage', 'chatOpen'],
  data() {
    return {
      pinned: null,
      connected: false,
      chatMessagesData: [],
      simplebarInstance: null,
      form: {
        message: "",
      },
    };
  },
  props: {
    imModerator: Boolean,
    donateAllows: Boolean,
    chatAllows: Boolean,
    session: String,
    episode: String,
  },
  components: {
    SimpleBar,
  },
  mounted() {
    var chat = document.getElementById('offcanvasChatMessages');
    if(chat) {
      chat.addEventListener('show.bs.offcanvas', this.chatOpenPanel);
      chat.addEventListener('hidden.bs.offcanvas', this.chatClosePanel);
    }
  },
  methods: {
    chatOpenPanel() {
      this.$emit('chatOpen', true);
    },
    chatClosePanel() {
      this.$emit('chatOpen', false);
    },
    async addMessage(message, attach = false, scroll = true) {
      console.log("New message", message);
      if (message !== '') {
        this.chatMessagesData.push(message);
        await nextTick();
        if (attach)
          this.attachCopyLinkHandler();
        if (scroll)
          this.scrollToEnd();
      }
    },
    getMessageClass(message) {
      if (message.system === true)
        return '';
      if (message.isGuest)
        return 'opacity-50';
      if (message.isDonation)
        return 'bg-warning';
      return 'bg-primary';
    },
    attachCopyLinkHandler() {
      const linkElement = document.querySelectorAll('a[data-link]');
      if (linkElement && linkElement.length > 0) {
        var vm = this;

        linkElement.forEach(function (currentValue) {
          currentValue.addEventListener('click', function () {
            vm.copyToClipboard(currentValue.dataset.link);
          });
        });

      }
    },
    copyToClipboard(message) {
      console.log('copy to clipboard:', message);
      try {
        write(message);
      }
      catch (e) {
        console.error(e);
      }
    },
    scrollToEnd: function () {
      var chatElement = document.getElementsByClassName("chat-conversation-list")[0];
      if(chatElement) {
        var lastElement = chatElement.children[chatElement.children.length - 1];
        if(lastElement)
          lastElement.scrollIntoView();
      }
    },
    profileAction(id, status) {
      if (confirm('Are you sure?')) {
        this.$emit('profileClick', id, status);
      }
    },
    getProfileId: function () {
      return localStorage.getItem('user.id');
    },
    formSubmit() { 
      if(this.form.message !== '') {
        this.$emit('sendMessage', this.form);
        this.form.message = '';
        this.$refs.txtMessage.focus();
      }
    }
  },
  computed: {
    isAuth: function () {
      return localStorage.getItem('user.id');
    },
  }
};
</script>

<template>

  <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasChatMessages" aria-labelledby="offcanvasChatMessagesLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasChatMessagesLabel">Stream chat</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body p-0">
      <div class="user-chat position-relative">
        
        <SimpleBar></SimpleBar>
        <div class="chat-conversation p-3 p-lg-4 overflow-auto" data-simplebar ref="current">
          <ul ref="pnlMessages" class="list-unstyled chat-conversation-list overflow-auto">
            <li v-for="data of chatMessagesData" :key="data.message" :class="{ right: `${data.align}` === 'right', left: `${data.align}` !== 'right', }" class="chat-list">
              <div class="conversation-list">
                <div class="chat-avatar" v-if="data.image && data.login">
                  <router-link :to="{ name: 'profile', params: { login: data.login } }" target="_blank">
                    <img :src="data.image" class="align-baseline" alt="" />
                  </router-link>
                </div>
                <div class="user-chat-content">
                  <div class="ctext-wrap mb-0">
                    <div class="ctext-wrap-content bg-opacity-50" :class="getMessageClass(data)">
                      <p class="mb-0 ctext-content" v-html="data.message"></p>
                    </div>
                    <div class="dropdown message-box-drop" v-if="!data.system && !data.isDonation">
                      <a class="dropdown-toggle" href="#" target="_self" role="button" data-bs-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="ri-more-2-fill"></i>
                      </a>
                      <div class="dropdown-menu">
                        <router-link :to="{ name: 'profile', params: { login: data.login } }" class="dropdown-item"
                          target="_blank">
                          <i class="ri-user-line me-2 text-muted align-bottom"></i> Profile
                        </router-link>
                        <a @click="profileAction(data.profileId, 'invite')" href="#" class="dropdown-item"
                          v-if="imModerator && getProfileId() !== data.profileId">
                          <i class="ri-user-add-line me-2 text-muted align-bottom"></i> Invite
                        </a>
                        <a @click="profileAction(data.id, 'pin')" href="#" class="dropdown-item" v-if="imModerator">
                          <i class="ri-pushpin-line me-2 text-muted align-bottom"></i> Pin
                        </a>
                        <a @click="profileAction(data.profileId, 'ban')" href="#" class="dropdown-item"
                          v-if="imModerator && getProfileId() !== data.profileId">
                          <i class="ri-forbid-line me-2 text-muted align-bottom"></i> Ban
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="conversation-name">
                    <small class="text-muted time">{{ data.time }}</small>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
  
        <div class="chat-input-section pt-0" v-if="isAuth">
          <form @submit.prevent="formSubmit">
            <div class="row gx-0">
              <div class="col p-2">
                <div class="input-group">
                  <input ref="txtMessage" type="text" v-model="form.message" :disabled="!chatAllows || !connected" class="form-control chat-input bg-light" maxlength="256" :class="{ disabled: !this.chatAllows || !connected }" placeholder="Enter Message..." />
                  <a class="btn btn-primary" v-if="chatAllows" :disabled="!chatAllows || !connected" @click="formSubmit" :class="{ disabled: !this.chatAllows || !connected }">
                    <i class="ri-send-plane-2-line"></i>
                  </a>
                  <a class="btn btn-warning" v-if="donateAllows" :disabled="!donateAllows && false" :href="'/payments/donation/' + this.episode + '/' + this.session" target="_blank">Donate</a>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="chat-input-section pt-3" v-if="!isAuth">
          <div class="text-center">
            <small>Only authorized users can post messages in the chat: 
            <router-link class="text-primary" to="/login">
              login
            </router-link>
            .</small>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>
